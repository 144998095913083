import React from 'react';
import { Stack } from '@mui/material';

function srcset(image: string) {
  return {
    src: `https://digiarchiv.aiscr.cz/api/img?size=medium&id=${image}`,
    srcSet: `https://digiarchiv.aiscr.cz/api/img?size=medium&id=${image} 2x`,
  };
}

const ImageMasonry = ({ images }) =>
  images && images.length > 0 ? (
    <Stack>
      {images.map((item, index) => (
        <img {...srcset(item)} alt={`i-${index}`} loading="lazy" />
      ))}
    </Stack>
  ) : (
    <div>No images uploaded.</div>
  );

export { ImageMasonry };
