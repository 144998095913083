import { gql } from '@apollo/client';

export const GetAllFilterTypesQueryName = 'GetAllFilterTypes';

export const GET_ALL_FILTER_TYPES_QUERY = gql`
  query ${GetAllFilterTypesQueryName} {
    getAllFilterTypes {
      eras { id, parentId, virtual, ordinal }
      findTypes { id, parentId, category }
      materials
      tags { id, name, public }
    }
  }
`;
