import React from 'react';
import { useSettings } from '../context/SettingsContext/useSettings';
import { Filters } from './Filters';
import { Stack } from '@mui/material';

import { FindLayersSettings } from './FindLayersSettings';

const FindToolbox = () => {
  const {
    store: { activeFindFilter },
  } = useSettings();

  return (
    <Stack gap={1}>
      <FindLayersSettings />
      <Filters key={activeFindFilter ? activeFindFilter.id : 'empty'} />
    </Stack>
  );
};

export { FindToolbox };
