import { gql } from '@apollo/client';

export const GetMyFindsQueryName = 'GetMyFinds';

export const GET_MY_FINDS_QUERY = gql`
  query ${GetMyFindsQueryName}($filters: Filters) {
    getMyFinds(filters: $filters) {
      id
      name
      evId
      createdAt
      description
      updatedAt
      foundAt
      material
      era
      type
      note
      siteId
      latitude
      longitude
      shared
      visibility
      draft
    }
  }
`;
