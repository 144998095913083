import React from 'react';
import { Link as BaseLink, Box, Button } from '@mui/material';
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_ColumnDef,
} from 'material-react-table';
import { Link } from 'react-router-dom';
import { Find } from './types';

const columns: MRT_ColumnDef<Find>[] = [
  {
    accessorKey: 'id',
    header: 'ID',
    size: 100,
    Cell: ({ renderedCellValue, row, cell }) => (
      <BaseLink
        underline="none"
        component={Link}
        to={`/f/${cell.getValue()}/edit`}
      >
        <span>{renderedCellValue}</span>
      </BaseLink>
    ),
  },
  {
    accessorKey: 'evId',
    header: 'EvID',
    size: 50,
  },
  {
    accessorKey: 'type',
    header: 'Find type',
    size: 180,
  },
  {
    accessorKey: 'material',
    header: 'Material',
    size: 120,
  },
  {
    accessorKey: 'era',
    header: 'Era',
    size: 180,
  },
  {
    accessorKey: 'description',
    header: 'Description',
    size: 220,
  },
  {
    Cell: ({ cell }) =>
      cell.getValue<Date | null>()
        ? cell.getValue<Date>()?.toLocaleDateString()
        : '-',
    accessorFn: (row) => row.foundAt && new Date(row.foundAt),
    header: 'Date found',
    size: 120,
  },
];

const gridStyles = { marginBottom: 6, display: 'grid' };

export default function FindGrid({ rows, loading = false }) {
  const table = useMaterialReactTable({
    columns,
    data: rows,
    enableRowSelection: true,
    enableToolbarInternalActions: false,
    enableColumnActions: false,
    enableColumnFilters: false,
    initialState: {
      pagination: { pageSize: 50, pageIndex: 0 },
      density: 'compact',
    },
    state: { isLoading: loading },
    muiPaginationProps: {
      showRowsPerPage: false,
    },
    muiTablePaperProps: {
      sx: gridStyles,
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box sx={{ display: 'flex', gap: '1rem', p: '4px' }}>
        <Button
          component={Link}
          to="create"
          color="primary"
          variant="contained"
        >
          Create Find
        </Button>
      </Box>
    ),
  });

  return <MaterialReactTable table={table} />;
}
