import React from 'react';
import { SettingsContext } from '.';

function useSettings() {
  const context = React.useContext(SettingsContext);

  if (!context) {
    throw new Error(`useSettings must be used within a SettingsProvider`);
  }

  const {
    store,
    addFindsFilter,
    disableFindsFilter,
    setMainDrawerIndex,
    updateFindsFilter,
    toggleFindLayer,
    toggleHeatmapLayer,
    toggleObjectLayer,
  } = context;

  return {
    store,
    addFindsFilter,
    disableFindsFilter,
    setMainDrawerIndex,
    updateFindsFilter,
    toggleFindLayer,
    toggleHeatmapLayer,
    toggleObjectLayer,
  };
}

export { useSettings };
