import React, { useState } from 'react';
import { Chip, Stack } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { FilterBuilder } from './FilterBuilder';
import { useSettings } from '../../context/SettingsContext/useSettings';

const Filters = () => {
  const {
    addFindsFilter,
    store: { activeFindFilter },
  } = useSettings();

  const [showEraFilter, setShowEraFilter] = useState(!!activeFindFilter?.eras);
  const [showSiteFilter, setShowSiteFilter] = useState(
    !!activeFindFilter?.sites
  );
  const [showFindTypeFilter, setShowFindTypeFilter] = useState(
    !!activeFindFilter?.findTypes
  );
  const [showMaterialFilter, setShowMaterialFilter] = useState(
    !!activeFindFilter?.materials
  );
  const [showTagFilter, setShowTagFilter] = useState(!!activeFindFilter?.tags);

  const handleAddEraFilter = () => {
    if (!activeFindFilter) {
      addFindsFilter({ eras: [] });
    }
    setShowEraFilter(true);
  };

  const handleAddSiteFilter = () => {
    if (!activeFindFilter) {
      addFindsFilter({ sites: [] });
    }
    setShowSiteFilter(true);
  };

  const handleAddFindTypeFilter = () => {
    if (!activeFindFilter) {
      addFindsFilter({ findTypes: [] });
    }
    setShowFindTypeFilter(true);
  };

  const handleAddMaterialFilter = () => {
    if (!activeFindFilter) {
      addFindsFilter({ materials: [] });
    }
    setShowMaterialFilter(true);
  };

  const handleAddTagFilter = () => {
    if (!activeFindFilter) {
      addFindsFilter({ tags: [] });
    }
    setShowTagFilter(true);
  };

  const handleLoadPresetFilter = () => {
    addFindsFilter({ materials: ['gold', 'silver'] });
  };

  return (
    <>
      <Stack direction="row" gap={1} flexWrap="wrap">
        {!showEraFilter && (
          <Chip
            icon={<FilterAltIcon />}
            size="small"
            label="Filter eras"
            variant="outlined"
            onClick={handleAddEraFilter}
          />
        )}
        {!showSiteFilter && (
          <Chip
            icon={<FilterAltIcon />}
            size="small"
            label="Filter sites"
            variant="outlined"
            onClick={handleAddSiteFilter}
          />
        )}
        {!showFindTypeFilter && (
          <Chip
            icon={<FilterAltIcon />}
            size="small"
            label="Filter find types"
            variant="outlined"
            onClick={handleAddFindTypeFilter}
          />
        )}
        {!showMaterialFilter && (
          <Chip
            icon={<FilterAltIcon />}
            size="small"
            label="Filter materials"
            variant="outlined"
            onClick={handleAddMaterialFilter}
          />
        )}
        {!showTagFilter && (
          <Chip
            icon={<FilterAltIcon />}
            size="small"
            label="Filter tags"
            variant="outlined"
            onClick={handleAddTagFilter}
          />
        )}
        <Chip
          size="small"
          label="Load preset"
          variant="outlined"
          onClick={handleLoadPresetFilter}
        />
      </Stack>
      {activeFindFilter && (
        <FilterBuilder
          showEraFilter={showEraFilter}
          showSiteFilter={showSiteFilter}
          showFindTypeFilter={showFindTypeFilter}
          showMaterialFilter={showMaterialFilter}
          showTagFilter={showTagFilter}
        />
      )}
    </>
  );
};

export { Filters };
