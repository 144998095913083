import React, { ReactNode } from 'react';
import { Box, BoxProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DRAWER_BLEEDING as drawerBleeding } from '../../consts';

const containerStyles = {
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
} as any;

const SceneBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== '$isMobile',
})<BoxProps & { $isMobile: boolean }>(({ theme, $isMobile }) => ({
  height: $isMobile ? `calc(100% - ${drawerBleeding}px)` : '100%',
}));

const SceneContainer = ({
  children,
  isMobile = false,
}: {
  isMobile: boolean;
  children: ReactNode;
}) => (
  <SceneBox $isMobile={isMobile} sx={containerStyles}>
    {children}
  </SceneBox>
);
export { SceneContainer };
