import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Stack,
  Switch,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { useSettings } from '../../context/SettingsContext/useSettings';

const accordionContentStyles = { p: 0 };
const labelStyles = { fontSize: 14 };
const summaryStyles = { p: 0, fontSize: 14 };

const FindLayersSettings = () => {
  const {
    store: { findLayersEnabled, heatmapLayerEnabled },
    toggleFindLayer,
    toggleHeatmapLayer,
  } = useSettings();

  const handleToggleFindsLayer = () => {
    toggleFindLayer();
  };

  const handleToggleHeatmapLayer = () => {
    toggleHeatmapLayer();
  };

  return (
    <Accordion elevation={0}>
      <AccordionSummary
        sx={summaryStyles}
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        Layers settings
      </AccordionSummary>
      <AccordionDetails sx={accordionContentStyles}>
        <Stack alignItems="flex-start">
          <FormControlLabel
            sx={labelStyles}
            value="Finds layer"
            control={
              <Switch
                size="small"
                checked={findLayersEnabled}
                onChange={handleToggleFindsLayer}
                color="primary"
              />
            }
            label="Finds layer"
            labelPlacement="start"
          />
          <FormControlLabel
            value="Heatmap layer"
            sx={labelStyles}
            control={
              <Switch
                size="small"
                checked={heatmapLayerEnabled}
                onChange={handleToggleHeatmapLayer}
                color="primary"
              />
            }
            label="Heatmap layer"
            labelPlacement="start"
          />
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export { FindLayersSettings };
