import React from 'react';
import { Drawer } from '@mui/material';

const drawerStyles = {
  width: 400,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: 400,
    boxSizing: 'border-box',
  },
};

const DesktopDrawer = ({ children }) => {
  return (
    <Drawer sx={drawerStyles} variant="persistent" anchor="right" open>
      {children}
    </Drawer>
  );
};

export { DesktopDrawer };
