import React from 'react';
import { useMutation } from '@apollo/client';
import loadable from '@loadable/component';
import Helmet from 'react-helmet';
import { useMediaQuery, useTheme } from '@mui/material';

import { AuthQueryName, SIGN_OUT_MUTATION } from '../../gql';
import { MapviewDrawer, WelcomeScreen } from '../../components';

const Scene = loadable(
  () => import('../../components/Scene').then((mod) => mod.Scene),
  { ssr: false }
);

interface HomeProps {
  isSignedIn: boolean;
}

const Home = ({ isSignedIn = false }: HomeProps) => {
  const theme = useTheme();
  const mobileBreakpoint = useMediaQuery(theme.breakpoints.down('md'));

  const [signOut] = useMutation(SIGN_OUT_MUTATION, {
    refetchQueries: [AuthQueryName],
  });

  if (!isSignedIn) {
    return <WelcomeScreen />;
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <Helmet title="Acolyte"></Helmet>
      <MapviewDrawer isMobile={mobileBreakpoint} onSignOut={signOut} />
      <Scene isMobile={mobileBreakpoint} />
    </div>
  );
};
export { Home };
