import React, { useState } from 'react';
import loadable from '@loadable/component';

import { SceneContainer } from '..';
import { GeolocationContext } from '../context';
import { useLayer } from '../LayerContext/useLayer';
import { useSettings } from '../context/SettingsContext/useSettings';

const MapControls = loadable(
  () => import('../MapControls').then((mod) => mod.MapControls),
  { ssr: false }
);

const MapView = loadable(
  () => import('../MapView').then((mod) => mod.MapView),
  { ssr: false }
);

const Scene = ({ isMobile = false }: { isMobile?: boolean }) => {
  const { layer } = useLayer();
  const {
    store: {
      activeFindFilter,
      findLayersEnabled,
      heatmapLayerEnabled,
      objectLayerEnabled,
    },
  } = useSettings();
  const [geolocate, setGeolocate] = useState(false);

  return (
    <GeolocationContext.Provider value={geolocate}>
      <SceneContainer isMobile={isMobile}>
        <MapControls onGeolocate={setGeolocate} />
        <MapView
          layer={layer}
          filters={activeFindFilter}
          findLayersEnabled={findLayersEnabled}
          heatmapLayerEnabled={heatmapLayerEnabled}
          objectLayerEnabled={objectLayerEnabled}
        />
      </SceneContainer>
    </GeolocationContext.Provider>
  );
};

export { Scene };
