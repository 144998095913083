import { useMemo, useState } from 'react';
import PhotoAlbum from 'react-photo-album';
import { Find } from '../types';
import { Box, Button, Stack, Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

interface FindGalleryProps {
  loading: boolean;
  siteId?: string;
  finds: Find[];
}

const getImageSrc = ({
  images,
  amcrData,
}: {
  images?: string[];
  amcrData?: { images: string[] };
}) => {
  if (amcrData?.images) {
    return `https://digiarchiv.aiscr.cz/api/img?size=medium&id=${amcrData.images[0]}`;
  }
  if (!images || images.length === 0) {
    return 'https://acolyte-images.s3.eu-central-1.amazonaws.com/no-image-alpha.png';
  }

  return `https://imagedelivery.net/3zdDZwDRXumMqC2LPbSSXw/${images[0]}/public`;
};

const getFormattedDate = (date?: string) => {
  if (!date) {
    return '-/-';
  }

  return new Date(date).toLocaleDateString();
};

const renderPhoto = ({
  photo,
  imageProps: { alt, style, ...restImageProps },
  layoutOptions,
}) => (
  <Box
    to={`/f/${photo.meta.id}/edit`}
    component={NavLink}
    sx={{
      border: '2px solid #eee',
      borderRadius: '4px',
      boxSizing: 'content-box',
      alignItems: 'center',
      width: style?.width,
      padding: `${layoutOptions.padding - 2}px`,
      paddingBottom: 0,
      textDecoration: 'none',
      color: 'inherit',
    }}
  >
    <img
      alt={alt}
      style={{ ...style, objectFit: 'cover', width: '100%', padding: 'none' }}
      {...restImageProps}
    />
    <div
      style={{
        paddingTop: '8px',
        paddingBottom: '8px',
      }}
    >
      <Stack flexShrink={1}>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontWeight="bold" fontSize={14}>
            {photo.meta.type || 'unidentified'}
          </Typography>
          <Typography fontSize={14}>
            {getFormattedDate(photo.meta.foundAt)}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="space-between">
          <Typography fontSize={11}>
            {photo.meta.amcrId || photo.meta.id}
          </Typography>{' '}
          <Typography fontSize={11}>{photo.meta.evId}</Typography>
        </Stack>
      </Stack>
    </div>
  </Box>
);

const FindGallery = ({ finds, siteId, loading }: FindGalleryProps) => {
  const [loadedCount, setLoadedCount] = useState(20);

  const handleLoadMore = () => {
    setLoadedCount((currentCount) => currentCount + 20);
  };

  const photos = useMemo(
    () =>
      (finds || []).slice(0, loadedCount).map((f) => ({
        src: getImageSrc(f),
        key: f.id,
        width: 1080,
        height: 800,
        meta: {
          id: f.id,
          evId: f.evId,
          amcrId: f.amcrId,
          foundAt: f.foundAt,
          type: f.type,
        },
      })),
    [loadedCount, finds]
  );

  if (loading) {
    return <div>loading...</div>;
  }

  return (
    <Stack gap={3} mb={3}>
      <PhotoAlbum
        targetRowHeight={200}
        padding={20}
        spacing={20}
        layout="rows"
        photos={photos}
        renderPhoto={renderPhoto}
      />
      <Stack direction="row" justifyContent="center">
        <Button
          variant="contained"
          fullWidth={false}
          disabled={loadedCount >= finds.length}
          type="button"
          onClick={handleLoadMore}
        >
          Load more
        </Button>
      </Stack>
    </Stack>
  );
};

export { FindGallery };
